<template>
  <div class="col-12">
    <!-- <Breadcrumb :home="home" :model="breadcrumbitems" /> -->
    <TabMenu id="tabmenu" :model="items" :activeIndex="activeIndex" />
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      home: { icon: 'pi pi-home', to: '/' },
      breadcrumbitems: [
        { label: 'Servidor', to: { name: 'servidores' } },
        { label: 'Margem consignável' },
      ],
      activeIndex: 0,
      tabIndex: {
        'servidores-margem': 0,
        consignacoes: 1,
        renegociacoes: 2,
        'compra-divida-lista': 3,
        'reserva-cartao-lista': 4,
        propostas: 5,
      },
      items: [
        { label: 'Margem', to: { name: 'servidores-margem' } },
        {
          label: 'Consignações',
          to: { name: 'consignacoes' },
        },
        {
          label: 'Renegociações',
          to: { name: 'renegociacoes' },
        },
        { label: 'Portabilidades', to: { name: 'compra-divida-lista' } },
        { label: 'Reserva de Cartão', to: { name: 'reserva-cartao-lista' } },
        { label: 'Propostas', to: { name: 'propostas' } },
      ],
    }
  },

  mounted() {
    this.activeIndex = this.tabIndex[this.$route.name]
    var element = document.getElementById('tabmenu')
    element.scrollIntoView()
  },

  updated() {
    this.activeIndex = this.tabIndex[this.$route.name]
  },
}
</script>

<style lang="scss" scoped>
::v-deep
  .p-tabmenu
  .p-tabmenu-nav
  .p-tabmenuitem
  .p-menuitem-link:not(.p-disabled):focus {
  box-shadow: none;
}

::v-deep .p-highlight .router-link-exact-active {
  color: var(--primary-color) !important;
}

::v-deep .p-menuitem-link {
  height: 103%;
}
</style>
